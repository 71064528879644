<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchKey"
                  class="d-inline-block mr-1"
                  placeholder="搜索关键字..."
              />
              <!--              <b-button
                              variant="primary"
                              :to="{ name: 'apps-store-edit'}"
                            >
                              <span class="text-nowrap">添加</span>
                            </b-button>-->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <template v-slot:head(#)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>
        <template v-slot:cell(#)="row">
          <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
          </b-form-checkbox>
        </template>

        <!-- Columns -->
        <template #cell(store_id)="data">
          <!--          <b-link-->
          <!--              :to="{ name: 'apps-store-edit', query: { id: data.item.id } }"-->
          <!--              class="font-weight-bold d-block text-nowrap"-->
          <!--          >-->
          #{{ data.item.store_id }}
          <!--          </b-link>-->
        </template>

        <!-- Column: Type -->
        <template #cell(store_type)="data">
          {{ getCodeLabel('store_type', data.item.store_type) }}
        </template>

        <!-- Column: create_time -->
        <template #cell(update_time)="data">
          {{ toTime(data.item.update_time) }}
        </template>

        <!-- Column: create_time -->
        <template #cell(expire_time_token)="data">
          {{ toTime(data.item.expire_time_token) }}
        </template>

        <!-- Column: image -->
        <template #cell(image)="data">
          <b-img :src="path + '/' + data.item.image" style="width: 40px; height: 40px" fluid alt="暂无图片"></b-img>
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('state', data.item.state)}`"
          >
            {{ getCodeLabel('state', data.item.state) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import storeUseList from '@/views/apps/store/store-modal/storeUseListMuti'
import storeStore from "@/views/apps/store/storeStore";


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  watch: {
    checked: {
      handler(newVal, oldVal) {
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value)=>{
          tableIdArray.push(value.store_id)
        })
        this.checked.forEach((value)=>{
          if (tableIdArray.indexOf(Number(value.store_id))>-1){
            show+=1;
          }
        })
        if (this.table.length === show && show > 0) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0){
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
    table:{
      handler(newVal, oldVal){
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value)=>{
          tableIdArray.push(value.store_id)
        })
        this.checked.forEach((value)=>{
          if (tableIdArray.indexOf(Number(value.store_id))>-1){
            show+=1;
          }
        })
        if (this.table.length === show && show > 0) {
          this.indeterminate = false
          this.allchecked = true
        }else if (show === 0){
          this.indeterminate = false
          this.allchecked = false
        }else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
  },
  data() {
    return {
      path: null,
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      checked: [],
    }
  },
  mounted() {
    var that = this
    that.path = process.env.VUE_APP_OSS_URL
    this.isCheckedObj = {}
    this.table.forEach(element => {
      this.$set(this.isCheckedObj, 'index' + element.id, false)
    })
    //父组件中传来的选中的元素 初始化的时候选中
    let selectIdArr = this.selectedIds.split(',')
    let selectNameArr = this.selectedNames.split(',')
    console.log(selectIdArr)
    console.log(selectNameArr)
    for (let i = 0; i < selectIdArr.length; i++) {
      this.$set(this.isCheckedObj, 'index' + selectIdArr[i], true)
      let store = {'store_id': selectIdArr[i], 'store_name': selectNameArr[i]}
      if (!isEmpty(store.store_id)) {
        this.checked.push(store)
      }
      this.$forceUpdate()
    }
  },
  props: {
    store_id: {
      type: String,
      default: ''
    },
    store_name: {
      type: String,
      default: ''
    }
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('store/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    getSelected: function () {
      return this.checked
    },
  },
  setup(props) {

    // Register module
    if (!store.hasModule('store')) store.registerModule('store', storeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('store')) store.unregisterModule('store')
    })

    const updateToken = function (id) {
      // 拼多多连接跳转
      // 新增按钮，店铺授权  url: api/pdd/oauth
      // api/pdd/refresh?storeNo=店铺编码
      // 新增店铺 店铺编码不可以重复
      window.open(process.env.VUE_APP_TOKEN_URL_REFRESH + '?storeNo=' + id)
    }

    // 点击全选按钮
    const toggleAll = function (checked) {
      let checkedStoreIds = [];
      this.checked.forEach(item =>{
        checkedStoreIds.push(Number(item.store_id))
      })
      this.table.forEach(element => {
        if (checked) {
          if(checkedStoreIds.indexOf(element.store_id) === -1){
            this.checked.push(element);
          }
        } else {
          //取消勾选
          for (let i = 0; i < this.checked.length; i++) {
            //在已勾选数组中找出要取消勾选的
            if (this.checked[i].store_id == element.store_id) {
              this.checked.splice(i, 1)
            }
          }
        }
        this.isCheckedObj['index' + element.id] = checked;
      });
      this.$forceUpdate()
    }
    // 复选框点击选中与取消
    const checkboxClick = function (item) {
      if (this.isCheckedObj['index' + item.id]) {
        //勾选
        this.checked.push(item)
      } else {
        //取消勾选
        for (let i = 0; i < this.checked.length; i++) {
          //在已勾选数组中找出要取消勾选的
          if (this.checked[i].store_id == item.store_id) {
            this.checked.splice(i, 1)
          }
        }
      }
      this.$forceUpdate()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      selectedIds,
      selectedNames,

      // UI
    } = storeUseList(props)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      selectedIds,
      selectedNames,
      updateToken,
      toggleAll,
      checkboxClick,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
