import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    condition: {
      //需要在js中修改的数据需要提前声明
      company_name: undefined,
      supplier_name: undefined,
      applier_name: undefined,
    },
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    updateCondition(state,condition){
      state.condition = condition
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/companyprepaid/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/companyprepaid/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/companyprepaid/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getInfoBySupplierId(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/companyprepaid/getInfoBySupplierId', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/companyprepaid/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/companyprepaid/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
