import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getCodeLabel } from '@core/utils/filter'
import { second ,toDate} from '@core/utils/dayjs/utils'

export default function companyprepaidUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'prepaid_no', label: '申请编号', sortable: true },
    { key: 'supplier_name', label: '收款单位', sortable: true },
    { key: 'company_name', label: '付款主体', sortable: true },
    { key: 'amount', label: '实际付款金额', sortable: true },
    { key: 'prepay_amount', label: '申请付款金额', sortable: true },
    { key: 'remark', label: '付款事由', sortable: true },
    { key: 'creator', label: '创建人', sortable: true,formatter:(value) => getCodeLabel("user",value)},
    { key: 'create_time', label: '创建时间', sortable: true ,formatter:(value)=> toDate(value)},
    { key: 'status', label: '审核状态', sortable: true },
    { key: 'actions', label: '操作' },

    /*{ key: 'company_id', label: '预付款主体', sortable: true },
    { key: 'prepay_type', label: '预付类型 prepay_type', sortable: true },

    { key: 'supplier_id', label: '供应商ID', sortable: true },

    { key: 'bank_account_type', label: '银行账户类型     purchase_order.account_type   转换为中文', sortable: true },
    { key: 'bank_account_name', label: '收款人户名     purchase_order.account_name', sortable: true },
    { key: 'bank_account_no', label: '收款人账号     purchase_order.account_no', sortable: true },
    { key: 'bank_name', label: '收款行及支行名称    purchase_order.bank_name', sortable: true },
    { key: 'bank_node_address', label: '银行地址    purchase_order.bank_node_address', sortable: true },
    { key: 'bank_swift_code', label: '联行号    purchase_order.swift_code', sortable: true },
    { key: 'bank_is_outlands', label: '是否境外     purchase_order.is_outlands', sortable: true },

    { key: 'attachments', label: '附件', sortable: true },
    { key: 'update_time', label: '更新时间', sortable: true },
    { key: 'updator', label: '更新人ID', sortable: true },*/

  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('prepaid_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const invoiceAmount = ref(0);
  const searchList = (ctx, callback) => {
    const condition = {...store.getters['companyprepaid/getCondition']}
    //删除一些条件
    condition.company_name = undefined
    condition.supplier_name = undefined
    condition.applier_name = undefined
    const params = {
      start: start.value,
      limit: limit.value,
      order_by: orderBy.value,
      order_desc: isSortDirDesc.value === true ? 'desc':'',
      purchase_order_id:config.purchase_order_id
    }
    Object.assign(params,condition)
    store
      .dispatch('companyprepaid/search', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        invoiceAmount.value = data.ext.invoiceAmount
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '付款申请单列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    invoiceAmount,

    refetchData,

    // Extra Filters
  }
}
