import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    commitCompanyPrepaid(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/oa/commitCompanyPrepaid', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    commitPurchaseContract(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/oa/commitPurchaseContract', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    commitPurchaseOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/oa/commitPurchaseOrder', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    commitPurchasePayment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/oa/commitPurchasePayment', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelWorkflow(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/oa/cancelWorkflow', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePurchaseContract(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/oa/changePurchaseContract', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelChangePurchaseContract(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/oa/cancelChangePurchaseContract', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePurchaseOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/oa/changePurchaseOrder', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelChangePurchaseOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/oa/cancelChangePurchaseOrder', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
