import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function companyUseList(config) {

  // Use toast
  const toast = useToast()

  const refListTable = ref(null)
  const condition = ref({})

  // Table Handlers
  const tableColumns = [
    { key: 'company_id', label: 'ID', sortable: true },
    { key: 'company_name', label: '名称', sortable: true },
    { key: 'nick_name', label: '简称', sortable: true },
    { key: 'store_name', label:'团队/店铺' , sortable: true},
    { key: 'company_kind' , label:'公司类别', sortable: true},
    { key: 'user_id', label: '负责人', sortable: true },
    { key: 'company_class', label: '供应商分类', sortable: true },
    { key: 'prepaid_amount', label: '预付款总金额', sortable: true },
    { key: 'status', label: '数据状态 ', sortable: true },
    { key: 'state', label: '启用', sortable: true },
    { key: 'bbm_buyer_id', label:'BBM买家ID', sortable: true},
    { key: 'one_company_id', label:'公司oneId', sortable: true},
    { key: 'one_supplier_id', label:'服务商oneId', sortable: true},
    { key: 'zt_company_id', label:'公司编码', sortable: true},
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const limitOption = [1,2,3]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('company_id')
  const isSortDirDesc = ref(true)
  const routeType=ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit, searchKey,routeType], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    if (routeType.value==0){
      routeType.value=config
    }
    store
        .dispatch('company/search', {
          search_key: searchKey.value,
          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          type:routeType.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
          company_name: condition.value.company_name,
          user_id: condition.value.user_id,
          status: condition.value.status,
          store_id:condition.value.store_id,
          company_kind:condition.value.company_kind,
          is_invoice:condition.value.is_invoice,
          state:condition.value.state,
          query_store:1,
        })
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals
          if(list.length > 0) {
            if (list[0].company_type !== 3) {
              for (let i = 0; i < tableColumns.length; i++) {
                if (tableColumns[i].key=="company_class"){
                  tableColumns.splice(i,1)
                }
                if (tableColumns[i].key=="prepaid_amount"){
                  tableColumns.splice(i,1)
                }
              }
            }
            for(let i=0;i<list.length;i++){
                list[i].store_id = list[i].ext.store_id
                list[i].store_name = list[i].ext.store_name
            }
          }
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '企业列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    limitOption,
    routeType,
    refetchData,
    condition,
    // Extra Filters
  }
}
