<template>
  <b-card style="width:100%">
    <b-form
        @submit.prevent="refreshTable"
        @reset.prevent="resetCondition"
    >
      <b-row>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="申请编号"
              label-for="orderNo"
              label-size="sm"
          >
            <b-form-input
                v-model="condition.prepaid_no"
                name="orderNo"
                class="d-inline-block mr-1"
                size="sm"
            />
          </b-form-group>
        </b-col>
<!--        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="采购订单号"
              label-for="purchaseOrderNo"
              label-size="sm"
          >
            <b-form-input
                v-model="condition.purchaseOrderNo"
                name="purchaseOrderNo"
                class="d-inline-block mr-1"
                size="sm"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="商品:"
              label-for="productCondition"
              label-size="sm"
          >
            <b-form-input
                v-model="condition.productCondition"
                name="productCondition"
                class="d-inline-block mr-1"
                size="sm"
                placeholder="请输入69码或名称"
            />
          </b-form-group>
        </b-col>-->

        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="状态"
              label-for="orderStatus"
              label-size="sm"
          >
            <v-select
                id="status"
                :options="getCodeOptions('contract_status').filter((item) => parseInt(item.value) < 4)"
                v-model="condition.status"
                name="orderStatus"
                class="select-size-sm"
                placeholder="请选择状态"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>

<!--        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="OA节点状态"
              label-for="oaNodeStatus"
              label-size="sm"
          >
            <v-select
                id="oaNodeStatus"
                :options="getCodeOptions('oa_node_status_payment')"
                v-model="condition.oaNodeStatus"
                name="orderStatus"
                class="select-size-sm"
                placeholder="请选择状态"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>-->

        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="付款主体:"
              label-for="company_name"
              label-size="sm"
          >
            <b-form-input
                id="company_name"
                size="sm"
                v-model="condition.company_name"
                name="companyId"
                @click="showSingleModal('签约主体')"
                readonly
                placeholder="点击搜索签约主体"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="收款单位:"
              label-for="supplier_name"
              label-size="sm"
          >
            <b-form-input
                id="supplier_name"
                size="sm"
                v-model="condition.supplier_name"
                name="supplier_name"
                @click="showSingleModal('供应商')"
                readonly
                placeholder="点击搜索供应商"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="申请人:"
              label-for="applier_name"
              label-size="sm"
          >
            <b-form-input
                id="applier_name"
                size="sm"
                v-model="condition.applier_name"
                name="applier_name"
                @click="showSingleModal('申请人')"
                readonly
                placeholder="点击搜索申请人"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-button
              variant="outline-primary"
              type="submit"
              class="mr-1"
          >
            <span class="align-right">查询</span>
          </b-button>

          <b-button
              variant="outline-primary"
              type="reset"
          >
            <span class="align-right">重置</span>
          </b-button>

        </b-col>
      </b-row>
    </b-form>
    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'+modalName"
        ref="singleModal"
    >

      <company-select
          ref="companySelect" :companyType="companyType" v-if="modalName === '签约主体' || modalName === '供应商' "
      />

      <user-select
          ref="applierSelect" :department-id="departmentId" v-else-if="'申请人' === modalName "
      />
    </b-modal>
  </b-card>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import { getCodeOptions } from '@core/utils/filter'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import companyprepaidStore from '@/views/apps/companyprepaid/companyprepaidStore'
import CompanySelect from '@/views/apps/company/CompanySelect'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'

export default {
  components: { UserSelect, CompanySelect },
  props: {},
  setup(props, { emit }) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('companyprepaid')) store.registerModule('companyprepaid', companyprepaidStore)

    const state = reactive({
      condition: {},
      modalName: '',
      companyType: '',
      //ref
      singleModal: null,
    })
    onMounted(()=>{
      state.condition = store.getters['companyprepaid/getCondition']
    })
    const methods = {
      showSingleModal(modalName) {
        if (modalName === '签约主体') {
          state.companyType = 'Group'
        } else if (modalName === '供应商') {
          state.companyType = 'Supplier'
        }

        state.modalName = modalName
        state.singleModal.show()
      },
      onSelectSingle() {
        let data = {}
        switch (state.modalName) {
          case '签约主体': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.company_id = data[0].company_id
              state.condition.company_name = data[0].company_name
            }
            break
          }
          case '供应商': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.supplier_id = data[0].company_id
              state.condition.supplier_name = data[0].company_name
            }
            break
          }
          case '申请人': {
            data = this.$refs.applierSelect.getSelected()
            if (data.length > 0) {
              state.condition.creator = data[0].user_id
              state.condition.applier_name = data[0].full_name
            }
            break
          }
        }
      },
      refreshTable() {

        emit('refreshTable')
      },
      resetCondition() {
        state.condition = {
          company_name: undefined,
          supplier_name: undefined,
          applier_name: undefined,
        }
        store.commit('companyprepaid/updateCondition', state.condition)
        methods.refreshTable()
      },
    }
    return {
      ...toRefs(state),
      departmentId:1,
      ...methods,
      //filter
      getCodeOptions
    }
  },
}
</script>

<style scoped>

</style>
