<template>

  <div>
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>

          </b-col>

          <!--routeType-->
          <b-col md="1">
            <b-form-input
                v-model="routeType"
                class="d-inline-block mr-1 hidden"
            />

          </b-col>
          <!-- Search -->
          <b-col
              cols="12"
              md="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
                  class="mr-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="exportExcel"
                  class="mr-1"
              >
                <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                />
                <span class="align-middle">导出</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <!--名称-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    :label="this.searchName"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.company_name"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <!--申请人-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="申请人"
                    label-for="user_id"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="user_id"
                      size="sm"
                      v-model="condition.user_name"
                      v-b-modal.modal-select-user
                      placeholder="点击搜索申请人"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-modal
                  id="modal-select-user"
                  ok-only
                  ok-title="确认"
                  @ok="onSelectUser"
                  cancel-title="取消"
                  centered
                  size="lg"
                  title="选择姓名"
              >
                <user-select
                    ref="myUserSelect" :userDepartment="userDepartment">
                </user-select>
              </b-modal>
              <!--状态-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="状态"
                    label-for="status"
                    label-size="sm"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('company_status')"
                      :clearable="false"
                      v-model="condition.status"
                      class="select-size-sm"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--团队/店铺-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="店铺名称"
                    label-for="store_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="store_name"
                      size="sm"
                      v-model="condition.store_name"
                      v-b-modal.modal-select-store
                      placeholder="点击搜索店铺名称"
                  />
                </b-form-group>
              </b-col>
              <!--店铺名称 modal-->
              <b-modal
                  id="modal-select-store"
                  ok-only
                  ok-title="确认"
                  @ok="onSelectStore"
                  cancel-title="取消"
                  centered
                  size="lg"
                  title="选择店铺"
              >
                <StoreListMutiSelect ref="storeList" :store_id="condition.store_id" :store_name="condition.store_name"/>
              </b-modal>
              <!--公司类别-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="公司类别"
                    label-for="company_kind"
                    label-size="sm"
                >
                  <v-select
                      id="company_kind"
                      :options="optionsType(routeType)"
                      :clearable="false"
                      v-model="condition.company_kind"
                      class="select-size-sm"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--是否开票-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="是否开票"
                    label-for="is_invoice"
                    label-size="sm"
                >
                  <v-select
                      id="is_invoice"
                      :options="getCodeOptions('yesno')"
                      :clearable="false"
                      v-model="condition.is_invoice"
                      class="select-size-sm"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--启用状态-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="启用状态"
                    label-for="state"
                    label-size="sm"
                >
                  <v-select
                      id="state"
                      :options="getCodeOptions('state')"
                      :clearable="false"
                      v-model="condition.state"
                      class="select-size-sm"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(company_id)="data">
          #{{ data.item.company_id }}
        </template>

        <!-- Column: Type -->
        <template #cell(company_type)="data">
          {{ getCodeLabel('company_type', data.item.company_type) }}
        </template>

        <!--        company_class-->
        <template #cell(company_class)="data">
          {{ getCodeLabel('company_class', data.item.company_class) }}
        </template>

        <!--公司类别-->
        <template #cell(company_kind)="data">
          <span v-if="routeType==1">{{ getCodeLabel('company_kind_QDS', data.item.company_kind) }}</span>
          <span v-if="routeType==3">{{ getCodeLabel('company_kind_GYS', data.item.company_kind) }}</span>
          <span v-if="routeType==6">{{ getCodeLabel('company_kind_ZDKH', data.item.company_kind) }}</span>
        </template>

        <!--        company_status-->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('company_status', data.item.status)}`"
          >
            {{ getCodeLabel('company_status', data.item.status) }}
          </b-badge>
        </template>

        <template #cell(user_id)="data">
          {{ getCodeLabel('user', data.item.user_id) }}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.state)}`"
            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <div v-if="data.item.company_type ===6 &&![9,27].includes(user.role_id)">
              <b-dropdown-item :to="{ name: 'apps-company-view', query: { id: data.item.id ,type:type} }">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">编辑企业</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'apps-contact-list', query: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">编辑联系人</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'apps-bankaccount-list', query: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">编辑银行账户</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="isEmpty(data.item.bbm_buyer_id)" @click="pushBBM(data.item)">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">推送BBMall</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="!isEmpty(data.item.bbm_buyer_id)" @click="updateBBM(data.item)">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">更新BBMall</span>
              </b-dropdown-item>
            </div>

            <div v-if="data.item.company_type !=6 ">
              <b-dropdown-item :to="{ name: 'apps-company-view', query: { id: data.item.id ,type:type} }">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">编辑企业</span>
              </b-dropdown-item>

<!--              <b-dropdown-item :to="{ name: 'apps-contact-list', query: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">编辑联系人</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'apps-bankaccount-list', query: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">编辑银行账户</span>
              </b-dropdown-item>-->
            </div>

            <!--
                        <b-dropdown-item v-if="data.item.company_type === 4" :to="{ name: 'apps-warehouse-list', query: { id: data.item.id,companyName:data.item.company_name } }">
                          <feather-icon icon="FileTextIcon" />
                          <span class="align-middle ml-50">仓库管理</span>
                        </b-dropdown-item>-->

            <div v-if="data.item.status == 3&&[29,31].includes(user.role_id)">
              <b-dropdown-item @click="changeStatus(data.item,4,'审核通过')">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">同意</span>
              </b-dropdown-item>
              <b-dropdown-item @click="changeStatus(data.item,1,'驳回')">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">驳回</span>
              </b-dropdown-item>
            </div>


            <b-dropdown-item v-if="data.item.company_type ===3&&user.role_id===14" v-b-modal.modal-company-prepaid
                             @click="changeCompanyId(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">添加预付款金额</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.company_type === 2&&user.role_id===14"
                             :to="{ name: 'apps-department-list', query: { id: data.item.id,companyName:data.item.company_name } }">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">部门管理</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.company_type ===1"
                             :to="{ name: 'apps-storesales-list', query: { storeId: data.item.id ,type:2} }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑销售员</span>
            </b-dropdown-item>

            <b-dropdown-item
                v-if="(data.item.company_type ===6||data.item.company_type ===1) &&![9,27,30].includes(user.role_id)"
                :to="{ name: 'apps-warehousestatement-list', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">账期设置</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status == 4 && data.item.company_type == 6" @click="showCheckModal(data.item)">
              <feather-icon icon="Edit3Icon"/>
              <span class="align-middle ml-50">客户名称变更</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <b-modal
          id="modal-company-prepaid"
          ok-only
          ok-title="关闭"
          centered
          size="xl"
          title="添加预付款"
          ref="company"
      >
        <company-prepaid-list :companyid="companyid" v-on:table="fromChildren">

        </company-prepaid-list>

      </b-modal>

      <!--变更客户名称-->
      <b-modal
          id="checkModal"
          @ok="finishCheck"
          ok-title="提交"
          cancel-title="取消"
          centered
          size="lg"
          title="客户名称变更"
      >
        <b-row>
          <!--旧名称-->
          <b-col md="6">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="旧名称"
                label-for="company_name_old"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="company_name_old"
                  size="sm"
                  readonly
                  v-model="companycheck.company_name_old"
              />
            </b-form-group>
          </b-col>
          <!--新名称-->
          <b-col md="6">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="新名称"
                label-for="company_name_new"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="company_name_new"
                  size="sm"
                  v-model="companycheck.company_name_new"
              />
            </b-form-group>
          </b-col>
          <!--旧简称-->
          <b-col md="6">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="旧简称"
                label-for="nick_name_old"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="nick_name_old"
                  size="sm"
                  readonly
                  v-model="companycheck.nick_name_old"
              />
            </b-form-group>
          </b-col>
          <!--新简称-->
          <b-col md="6">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="新简称"
                label-for="nick_name_new"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="nick_name_new"
                  size="sm"
                  v-model="companycheck.nick_name_new"
              />
            </b-form-group>
          </b-col>
          <!--变更原因-->
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="变更原因"
                label-for="reason"
                label-size="sm"
                class="mb-1"
            >
              <b-form-textarea
                  id="textarea"
                  v-model="companycheck.reason"
                  rows="3"
                  max-rows="6"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>


    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, watch} from '@vue/composition-api'
import companyUseList from './companyUseList'
import companyStore from './companyStore'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import {useRouter} from '@core/utils/utils'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {getUserData} from "@/auth/utils";
import CompanyPrepaidList from "@/views/apps/companyprepaid/CompanyPrepaidList";
import Ripple from "vue-ripple-directive";
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import {useToast} from "vue-toastification/composition";
import ModalSelect from "@/views/components/modal/ModalSelect";
import StoreListMutiSelect from "@/views/apps/store/store-modal/StoreListMutiSelect";
import companycheckStore from "@/views/apps/companycheck/companycheckStore";
import axios from "@/libs/axios";

export default {
  components: {
    CompanyPrepaidList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
    UserSelect,
    ModalSelect,
    StoreListMutiSelect,
  },
  data() {
    return {
      type: '',
      user: {},
      companyid: 0,
      advanced_search_modal: false,
      userDepartment: "",
      searchName: '',
      companycheck: {},
    }
  },
  directives: {
    Ripple,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('company/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    getType: function () {
      return this.type
    },

  },

  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('company')) store.registerModule('company', companyStore)
    if (!store.hasModule('companycheck')) store.registerModule('companycheck', companycheckStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('company')) store.unregisterModule('company')
      if (store.hasModule('companycheck')) store.unregisterModule('companycheck')
    })

    const jump = function (res) {
      if (res.item.company_type === 1) {
        return "apps-company-edit"
      }
      if (res.item.company_type === 3) {
        return "apps-company-edit-qds"
      }
    }

    const submitMoney = function () {

    }

    const changeCompanyId = function (params) {
      this.companyid = params.id
    }

    const fromChildren = function (val) {
      if (val === "success") {
        // this.$refs['company'].hide()
      } else {

      }
      refetchData()


      // console.log("这是从子页面传来的", this.salesOrderItem)
    }

    const router = useRouter()

    const advanced_search = function () {
      this.advanced_search_modal = !this.advanced_search_modal;
    }

    const onSelectUser = function (bvModalEvt) {
      let checked = this.$refs.myUserSelect.getSelected()[0];
      this.condition.user_name = checked.full_name
      this.condition.user_id = checked.id
      this.$forceUpdate()
    }


    const resetCondition = function () {
      this.condition = {}
      this.refetchData()
    }

    const searchByCondition = function () {
      this.refetchData()
    }

    const changeStatus = function (data, status, information) {
      if (confirm('是否' + information)) {
        store.dispatch('company/changeStatus', {id: data.company_id, status: status}).then(res => {
          if (res.data.code == 0) {
            toast.success("成功!")
            refetchData()
          } else {
            toast.error("失败!")
          }
        })
      }
    }

    const pushBBM = function (data) {
      this.$swal({
        title: '是否推送BBMall?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('company/pushBBMall', {companyId: data.company_id}).then(res => {
            if (res.data.code == 0) {
              //弹窗
              this.$swal({
                icon: 'success',
                title: res.data.data,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              //重新加载表格
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })
    }

    const updateBBM = function (data) {
      this.$swal({
        title: '是否更新BBMall?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('company/updateBBMall', {companyId: data.company_id}).then(res => {
            if (res.data.code == 0) {
              //弹窗
              this.$swal({
                icon: 'success',
                title: res.data.data,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              //重新加载表格
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })
    }

    const optionsType = function (res) {
      if (res === "1" || res === 1) {
        return getCodeOptions('company_kind_QDS')
      }
      if (res === "2" || res === 2) {
        return []
      }
      if (res === "3" || res === 3) {
        return getCodeOptions('company_kind_GYS')
      }
      if (res === "4" || res === 4) {
        return []
      }
      if (res === "5" || res === 5) {
        return []
      }
      if (res === "6" || res === 6) {
        return getCodeOptions('company_kind_ZDKH')
      }
    }

    const onSelectStore = function () {
      let store_id_arr = []
      let store_name_arr = []
      let select = this.$refs.storeList.getSelected()
      for (let i = 0; i < select.length; i++) {
        store_id_arr.push(select[i].store_id)
        store_name_arr.push(select[i].store_name)
      }
      this.condition.store_name = store_name_arr.toString()
      this.condition.store_id = store_id_arr.toString()
      this.$forceUpdate()
    }

    const showCheckModal = function (item) {
      this.companycheck.company_id = item.id
      this.companycheck.company_name_old = item.company_name
      this.companycheck.nick_name_old = item.nick_name
      this.companycheck.company_name_new = ''
      this.companycheck.nick_name_new = ''
      this.companycheck.reason = ''
      this.$forceUpdate()
      this.$bvModal.show('checkModal')

    }

    const finishCheck = function (checkModal) {
      if (isEmpty(this.companycheck.company_name_new) && isEmpty(this.companycheck.nick_name_new)) {
        toast.error('变更名称、变更简称不能都为空！')
        checkModal.preventDefault();
        return false
      }
      store.dispatch('companycheck/save', this.companycheck).then(res => {
        if (res.data.code == 0) {
          this.$swal({
            icon: 'success',
            title: '已提交!',
            text: '请等待管理员审核',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else {
          this.$swal({
            icon: 'error',
            title: '提交失败!',
            text: res.data.data,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        }
      })

    }

    const exportExcel = function () {
      const params = {
        company_type: this.type,
        company_name: condition.value.company_name,
        user_id: condition.value.user_id,
        status: condition.value.status,
        store_id:condition.value.store_id,
        company_kind:condition.value.company_kind,
        is_invoice:condition.value.is_invoice,
        state:condition.value.state,
      }
      axios({
        url: 'api/exportexcelfile/exportCompany',
        method: 'get',
        params: params,
        responseType: 'blob',
      }).then(res => {
        toast.success('导出成功')
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = getCodeLabel('company_type',this.type) + new Date().getTime()
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data)
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })

    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      routeType,
      condition,

    } = companyUseList(router.route.value.query.type)


    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      routeType,
      refetchData,
      jump,
      pushBBM,
      updateBBM,
      condition,
      onSelectStore,
      optionsType,
      showCheckModal,
      finishCheck,
      exportExcel,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      submitMoney,
      changeCompanyId,
      fromChildren,
      advanced_search,
      onSelectUser,
      resetCondition,
      searchByCondition,
      changeStatus,
    }
  },
  watch: {
    $route(to, from) {
      this.routeType = this.$route.query.type
      this.advanced_search_modal = false
      this.resetCondition()
      switch (this.routeType) {
        case 1 :
          this.searchName = '渠道商名称'
          break
        case 2 :
          this.searchName = '集团公司名称'
          break
        case 3 :
          this.searchName = '供应商名称'
          break
        case 4 :
          this.searchName = '仓库方名称'
          break
        case 5 :
          this.searchName = '物流公司名称'
          break
        case 7 :
          this.searchName = '装卸队名称'
          break
        default:
          this.searchName = '名称'
      }
    }
  },
  created() {
    this.type = this.$route.query.type || 0
    const userData = getUserData()
    this.user = userData
    switch (this.type) {
      case '1' :
        this.searchName = '渠道商名称'
        break
      case '2':
        this.searchName = '集团公司名称'
        break
      case '3':
        this.searchName = '供应商名称'
        break
      case '4':
        this.searchName = '仓库方名称'
        break
      case '5':
        this.searchName = '物流公司名称'
        break
      case '7':
        this.searchName = '装卸队名称'
        break
      default:
        this.searchName = '名称'
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
