import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import { ref } from '@vue/composition-api'
import { oaMenu } from './oaMenu'

export default function oa(refresh) {
  const loadingVisible = ref(false)
  const toast = useToast()

  const commitPurchaseOrder = function (id) {
    handleRequest('oa/commitPurchaseOrder', id)
  }
  const commitCompanyPrepaid = function (id) {
    handleRequest('oa/commitCompanyPrepaid', id)
  }
  const commitPurchaseContract = function (id) {
    handleRequest('oa/commitPurchaseContract', id)
  }
  const commitPurchasePayment = function (id) {
    handleRequest('oa/commitPurchasePayment', id)
  }
  const cancelWorkflow = function (object_id, objectType) {
    loadingVisible.value = true
    store.dispatch('oa/cancelWorkflow', {
      'object_id': object_id,
      'object_type': objectType
    })
      .then(res => {
        handleResult(res)
      })
  }
  const changePurchaseContract = function (id) {
    handleRequest('oa/changePurchaseContract', id)
  }
  const cancelChangePurchaseContract = function (id) {
    handleRequest('oa/cancelChangePurchaseContract', id)
  }
  const changePurchaseOrder = function (id) {
    handleRequest('oa/changePurchaseOrder', id)
  }
  const cancelChangePurchaseOrder = function (id) {
    handleRequest('oa/cancelChangePurchaseOrder', id)
  }
  const handleRequest = function (url, id) {
    loadingVisible.value = true
    store.dispatch(url, {
      id: id
    })
      .then(res => {
        handleResult(res)
      })
  }

  const handleResult = function (res) {
    loadingVisible.value = false
    if (res.data.code === 0) {
      refresh()
      toast.success(res.data.data)
    } else {
      toast.error(res.data.data || '服务器内部错误')
    }
  }
  return {
    oaMenu,
    commitPurchaseOrder,
    commitPurchaseContract,
    commitPurchasePayment,
    cancelWorkflow,
    changePurchaseContract,
    cancelChangePurchaseContract,
    changePurchaseOrder,
    cancelChangePurchaseOrder,
    commitCompanyPrepaid,
    loadingVisible
  }
}


