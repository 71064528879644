<template>
  <div>
    <b-dropdown-group id="requireOA">
      <b-dropdown-item v-if="oaMenu.commit.includes(model.status)"
                       @click="commitWorkflow(model.id)"
      >
        <feather-icon icon="FileTextIcon"/>
        <span class="align-middle ml-50">提交审核</span>
      </b-dropdown-item>

      <b-dropdown-item v-if="oaMenu.cancel.includes(model.status)"
                       @click="cancelWorkflow(model.id,table)"
      >
        <feather-icon icon="EditIcon"/>
        <span class="align-middle ml-50">撤销审核</span>
      </b-dropdown-item>

      <b-dropdown-group v-if="requireChange">
        <b-dropdown-item v-if="oaMenu.commitChange.includes(model.status) || model.status === 8"
                         :to="{ name: `apps-${url}-edit`, params:{readonly:0} ,query:editQuery}"
        >
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">变更编辑</span>
        </b-dropdown-item>

        <b-dropdown-item v-if="oaMenu.commitChange.includes(model.status) || model.status === 8"
                         @click="changeWorkflow(model.id)"
        >
          <feather-icon icon="FileTextIcon"/>
          <span class="align-middle ml-50">变更提交OA审核</span>
        </b-dropdown-item>

        <b-dropdown-item v-if="oaMenu.cancelChange.includes(model.status)"
                         @click="cancelChangeWorkflow(model.id)"
        >
          <feather-icon icon="FileTextIcon"/>
          <span class="align-middle ml-50">撤销变更</span>
        </b-dropdown-item>
      </b-dropdown-group>
    </b-dropdown-group>

    <!-- 加载modal -->
    <b-modal
        id="loadingModal"
        centered
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        :visible="loadingVisible"
    >
      <p class="my-4">正在提交，请稍候!</p>
    </b-modal>
  </div>

</template>

<script>
import { computed, reactive, toRefs } from '@vue/composition-api'
import store from '@/store'
import oaStore from '@/views/apps/oa/oaStore'
import oa from './oa'

export default {
  name: 'OaComponent',
  props: {
    model: {
      type: Object
    },
    url: {
      type: String
    },
    table: {
      type: String,
    },
    contractType: {}
  },
  setup(props, { emit }) {
    const model = props.model
    const table = props.table
    const contractType = props.contractType

    // Register module
    if (!store.hasModule('oa')) {
      store.registerModule('oa', oaStore)
    }

    const data = reactive({
      editQuery: { id: model.id },

    })

    const requireChange = computed(() => table === 'contract' || table === 'purchase_order')

    const refresh = () => {
      emit('refreshTable')
    }

    const oaMethods = oa(refresh)

    const methods = {
      commitWorkflow(id) {
        switch (table) {
          case 'contract':
            oaMethods.commitPurchaseContract(id)
            break
          case 'purchase_order':
            oaMethods.commitPurchaseOrder(id)
            break
          case 'purchase_payment':
            oaMethods.commitPurchasePayment(id)
            break
          case 'company_prepaid':
            oaMethods.commitCompanyPrepaid(id)
            break
        }
      },
      changeWorkflow(id) {
        switch (table) {
          case 'contract':
            oaMethods.changePurchaseContract(id)
            break
          case 'purchase_order':
            oaMethods.changePurchaseOrder(id)
            break
        }
      },
      cancelChangeWorkflow(id) {
        switch (table) {
          case 'contract':
            oaMethods.cancelChangePurchaseContract(id)
            break
          case 'purchase_order':
            oaMethods.cancelChangePurchaseOrder(id)
            break
        }
      },

    }

    if (table === 'contract' && contractType) {
      data.editQuery['type'] = contractType
    }

    return {
      ...oaMethods,
      ...toRefs(data),
      requireChange,
      ...methods,
    }
  }
}
</script>

<style scoped>

</style>
