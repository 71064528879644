<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>


          <!-- Search -->
          <b-col
              cols="12"
              md="3"
          >

            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-purchaseorder-list'}"
                  v-if="orderId"
              >
                <span class="text-nowrap">返回订单列表</span>
              </b-button>
            </div>
          </b-col>
          <b-col md="3">

          </b-col>
        </b-row>
        <b-row>
          <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <search @refreshTable=refetchData />
          </b-col>
        </b-row>

      </div>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('contract_status',data.item.status)}`"
          >
            {{ getCodeLabel('contract_status', data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-companyprepaid-view', query: { id: data.item.id ,},params:{readonly:true} }">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <div>
              <oa-component :model="data.item" url="companyprepaid" table="company_prepaid"
                            @refreshTable=refetchData
              ></oa-component>

              <b-dropdown-item v-if="oaMenu.edit.includes(data.item.status)"
                               :to="{ name: 'apps-companyprepaid-edit', query: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">编辑</span>
              </b-dropdown-item>

            </div>

          </b-dropdown>
        </template>

      </b-table>


      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BLink,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted, reactive, toRefs } from '@vue/composition-api'
import { avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions, toDate, toTime } from '@core/utils/filter'
import companyprepaidUseList from './companyprepaidUseList'
import companyprepaidStore from './companyprepaidStore'
import { oaMenu } from '@/views/apps/oa/oaMenu'
import OaComponent from '@/views/apps/oa/OaComponent'
import { useRouter } from '@core/utils/utils'
import { getUserData } from '@/auth/utils'
import Search from '@/views/apps/companyprepaid/search'

export default {
  components: {
    Search,
    OaComponent,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
    changeState: function (data) {
      if (confirm("确定删除吗？")){
        store.dispatch('companyprepaid/state', {
          id: data.id,
          state: 0
        })
            .then(res => {
              this.refetchData()
            })
      }
    },
    VMICommit(id, status) {
      store.dispatch('companyprepaid/changeStatus', {
        id: id,
        status: status
      })
          .then(res => {
            this.refetchData()
          })
    }
  },
  data() {
    return{
      user:{}
    }
  },
  setup() {
    // Register module
    if (!store.hasModule('companyprepaid')) store.registerModule('companyprepaid', companyprepaidStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('companyprepaid')) store.unregisterModule('companyprepaid')
    })

    const router = useRouter()

    const state = reactive({
      orderId:undefined,
    })
    state.orderId = router.route.value.query.order_id

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      invoiceAmount,

      // UI
    } = companyprepaidUseList({
      purchase_order_id: state.orderId
    })

    return {
      ...toRefs(state),
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      invoiceAmount,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toDate,
      toTime,
      oaMenu,
    }
  },
  created() {
    this.user = getUserData()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
